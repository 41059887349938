<template>
  <v-row>
    <v-col cols="10">
      <div class="" align="center" jusitfy="center">
        <v-container class="iframe-wrapper">
          <div class="iframe-container">
            <iframe allow="camera; microphone" id="kioskApp" src="https://md-kiosk-simulator.netlify.app"
              frameborder="1px"></iframe>
            <span>{{ innWidth }}</span>
          </div>
        </v-container>
        <v-btn @click="logout()" color="error">Click to Reload the Kiosk App</v-btn>
      </div>
    </v-col>
    <v-col cols="2" align-self="center" align="center">
      <span class="font-weight-bold">Login Details</span>
      <br>
      <br>
      <span class="font-weight-bold">Howard Circuit Court</span>
      <br><br>
      <span class="font-weight-bold">Email</span>
      <br>
      <span>howardcc@arsconnect.com</span>
      <br>
      <span class="font-weight-bold">Password</span>
      <br>
      <v-btn @click="showPasswordH = true" v-if="!showPasswordH" color="primary">Show Password</v-btn>
      <span v-else>backspace</span>
      <br>
      <br>
      <br>
      <span class="font-weight-bold">Catonsville District Court</span>
      <br><br>
      <span class="font-weight-bold">Email</span>
      <br>
      <span>catonsvilledc@arsconnect.com</span>
      <br>
      <span class="font-weight-bold">Password</span>
      <br>
      <v-btn @click="showPasswordC = true" v-if="!showPasswordC" color="primary"> Show Password</v-btn>
      <span v-else>backspace</span>

      <br><br><br>
      <span class="pa-2">*Please click on <strong>'Click to Reload the Kiosk App'</strong> if unable to view icons or
        maps*</span>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: "FixedIframe",
  data() {
    return {
      innWidth: null,
      showPasswordH: false,
      showPasswordC: false
    };
  },
  methods: {
    logout() {
      var iframe = document.getElementById("kioskApp")
      var src = iframe.src;
      iframe.src = src;
    },
  },
  mounted() {
    this.innWidth = window.innerWidth;
  },
};
</script>

<style scoped>
.iframe-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 87vh;
  /* Full height of the viewport */
  overflow: hidden;
}

.iframe-container iframe {
  width: 1920px;
  height: 1080px;
  transform: scale(0.6);
  margin: 2px;
  border: none;
  /* Optional: removes the default border around the iframe */
}

@media screen and (max-width: 2600px) {
  .iframe-container iframe {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 2000px) {
  .iframe-container iframe {
    transform: scale(0.7);
  }
}

/* For 14" MacBook screens (or similar) */
@media screen and (max-width: 1600px) {
  .iframe-container iframe {
    transform: scale(0.6);
  }
}
</style>
